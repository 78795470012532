import { navigate } from "gatsby"

const hashStringify = obj =>
  Object.entries(obj).reduce(
    (result, [key, value], index, array) =>
      index + 1 === array.length ? `${result}${key}=${value}` : `${result}${key}=${value}&`,
    "#"
  )

const hashParse = string =>
  string.indexOf("#") === 0
    ? string
        .replace("#", "")
        .split("&")
        .reduce((result, entries) => {
          const [key, value] = entries.split("=")
          // eslint-disable-next-line no-param-reassign
          result[key] = value
          return result
        }, {})
    : {}

const useQueryParams = fallback => {
  const isSSR = typeof window === "undefined"
  if (isSSR) return { queryParams: fallback || {} }
  const queryParams = hashParse(window.location.hash)

  const deleteParam = name => {
    const urlParams = hashParse(window.location.hash)
    delete urlParams[name]
    const paramsString = hashStringify(urlParams)
    navigate(`${window.location.pathname}${paramsString}`, { replace: true })
  }

  const addParam = newParams => {
    const paramsArray = Array.isArray(newParams) ? newParams : [newParams]
    const urlParams = hashParse(window.location.hash)
    paramsArray.forEach(({ name, value }) => {
      urlParams[name] = value
    })
    const paramsString = hashStringify(urlParams)
    navigate(`${window.location.pathname}${paramsString}`, { replace: true })
  }

  return { queryParams, deleteParam, addParam }
}

export default useQueryParams
