import React from "react"

import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import IconClock from "assets/svg/blog/clock.svg"
import Button from "components/common/Button"
import Text from "components/common/Text"
import urls from "config/urls"
import addTrailingSlash from "helpers/addTrailingSlash"

const StyledHero = styled.div`
  min-height: 140px;
  height: 100%;
  background: ${({ backgroundImageSource }) => `url(${process.env.GATSBY_API_URL}${backgroundImageSource})`};
  background-size: cover;
  background-position: center;
  position: relative;
`

const StyledCategoryTile = styled.div`
  position: absolute;
  padding: 1.5rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.color.text.inverted};
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
`

const StyledTitleTile = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`

const StyledTitle = styled(Text)`
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
`

const StyledTitleTime = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledClockIcon = styled.div`
  max-width: 1.3rem;
  height: 100%;
  width: 100%;
  max-height: 1.3rem;
  margin-right: 0.5rem;

  path {
    fill: ${({ theme }) => theme.color.text.inverted};
  }
`

const StyledDetails = styled.div`
  padding: 2rem;
`

const StyledSummary = styled.p`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.4rem;
  overflow: hidden;
`

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.5rem;

  ${({ theme }) => theme.mq.xxs} {
    display: grid;
    grid-gap: 2rem;
    justify-content: stretch;
  }
`

const StyledAuthorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0.5rem;
  text-transform: uppercase;

  justify-self: center;
`

const StyledAuthorImage = styled.div`
  width: 45px;
  height: 45px;
  background: ${({ backgroundImageSource }) => `url(${process.env.GATSBY_API_URL}${backgroundImageSource})`};
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  grid-row: 1/4;
`

const stylesForBlogGrid = css`
  &:nth-child(1) {
    grid-area: area_1;
  }
  &:nth-child(2) {
    grid-area: area_2;
  }
  &:nth-child(3) {
    grid-area: area_3;

    ${StyledSummary} {
      max-height: calc(4 * 1.8rem);
    }
  }
  &:nth-child(4) {
    grid-area: area_4;
  }
  &:nth-child(5) {
    grid-area: area_5;
  }
  &:nth-child(6) {
    grid-area: area_6;

    ${StyledSummary} {
      max-height: calc(4 * 1.8rem);
    }
  }
  &:nth-child(7) {
    grid-area: area_7;
  }

  ${({ theme }) => theme.mq.xs} {
    grid-area: unset !important;
  }
`

const StyledTile = styled.article`
  ${({ theme }) => theme.gradient.linear.cyan_light_to_medium_dark};
  box-shadow: ${({ theme }) => theme.shadow.medium};
  display: grid;
  grid-template-rows: 1fr auto;
  grid-auto-flow: column;

  ${({ blogGrid }) => blogGrid && stylesForBlogGrid}
`

const parseDate = date => {
  if (!date) return ""
  const dateObj = new Date(date)
  return dateObj ? `${dateObj.getDate()}.${`0${dateObj.getMonth() + 1}`.slice(-2)}.${dateObj.getFullYear()}` : ""
}

const BlogItem = ({ title, readingTime, summary, category, date, image, author, slug, blogGrid }) => (
  <StyledTile blogGrid={blogGrid}>
    <StyledHero backgroundImageSource={image.url}>
      <StyledCategoryTile color={category.color}>{category.Name}</StyledCategoryTile>
      <StyledTitleTile>
        <StyledTitle bold fontSize="1.2rem" inverted uppercase>
          {title}
        </StyledTitle>
        {readingTime && (
          <StyledTitleTime bold inverted>
            <StyledClockIcon as={IconClock} />
            {`${readingTime} min`}
          </StyledTitleTime>
        )}
      </StyledTitleTile>
    </StyledHero>
    <StyledDetails>
      <StyledSummary>{summary}</StyledSummary>
      <StyledFooter>
        <StyledAuthorWrapper>
          <StyledAuthorImage backgroundImageSource={author && author.Avatar.url} />
          <Text bold fontSize="1rem" inverted uppercase>
            {author && `${author.First_name} ${author.Last_name}`}
          </Text>
          <Text fontSize="1rem" inverted uppercase>
            {author && author.Position}
          </Text>
          <Text fontSize="1rem" inverted uppercase>
            {parseDate(date)}
          </Text>
        </StyledAuthorWrapper>
        <Button to={addTrailingSlash(`${urls.blog.url}${slug}`)}>Read&nbsp;more</Button>
      </StyledFooter>
    </StyledDetails>
  </StyledTile>
)

BlogItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  readingTime: PropTypes.number.isRequired,
  summary: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  author: PropTypes.shape({
    First_name: PropTypes.string.isRequired,
    Last_name: PropTypes.string.isRequired,
    Position: PropTypes.string.isRequired,
    Avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  date: PropTypes.shape({}).isRequired,
  slug: PropTypes.string.isRequired,
}

export default BlogItem
