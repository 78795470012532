import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "components/common/Container"

import BlogItem from "../BlogItem"

const StyledMainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 2fr 1fr 2fr;
  grid-gap: 2rem;
  margin-bottom: 4rem;
  min-height: 755px;
  grid-template-areas:
    "area_1 area_1 area_2 area_3"
    "area_1 area_1 area_6 area_3"
    "area_4 area_5 area_6 area_7";
  transition: 0.2s;
  opacity: ${({ animation }) => (animation ? 0 : 1)};

  ${({ theme }) => theme.mq.m} {
    grid-template-columns: repeat(3, 1fr);
    min-height: 1153px;
    grid-template-areas:
      "area_1 area_1 area_2"
      "area_1 area_1 area_5"
      "area_3 area_4 area_5"
      "area_6 area_6 area_7";
  }

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
    min-height: 1550px;
    grid-template-rows: 1fr;
    grid-template-areas:
      "area_1 area_1"
      "area_2 area_4"
      "area_3 area_4"
      "area_5 area_5"
      "area_6 area_7";
  }
  ${({ theme }) => theme.mq.xs} {
    min-height: unset;
    grid-template-columns: unset;
    grid-template-areas: unset;
    grid-template-rows: unset;
  }
`
const BlogArticlesGrid = ({ posts }) => {
  const [animation, setAnimation] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [localPosts, setLocalPosts] = useState(posts)
  useEffect(() => {
    if (loaded) {
      setAnimation(true)
      setTimeout(() => {
        setAnimation(false)
        setLocalPosts(posts)
      }, 200)
    } else setLoaded(true)
  }, [posts])

  return (
    <Container size="xxl">
      <StyledMainGrid animation={animation}>
        {localPosts &&
          localPosts.map(({ category, summary, author, main_image, Title, published_at, reading_time, slug }) => (
            <BlogItem
              author={author}
              blogGrid
              category={category}
              date={published_at}
              image={main_image}
              readingTime={reading_time}
              slug={slug}
              summary={summary}
              title={Title}
            />
          ))}
      </StyledMainGrid>
    </Container>
  )
}

BlogArticlesGrid.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      Title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      reading_time: PropTypes.string.isRequired,
      published_at: PropTypes.string.isRequired,
      main_image: PropTypes.shape({
        url: PropTypes.string,
      }),
      author: PropTypes.shape({
        First_name: PropTypes.string.isRequired,
        Last_name: PropTypes.string.isRequired,
        Position: PropTypes.string.isRequired,
        Avatar: PropTypes.shape({
          url: PropTypes.string,
        }),
      }).isRequired,
      category: PropTypes.shape({
        Name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export default BlogArticlesGrid
