import React from "react"

import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import BlogContent from "components/pages/blog/BlogContent"

const Blog = ({ location }) => (
  <>
    <Seo seoPageName="Blog" />
    <TransitionWrapper location={location}>
      <SubpageHeader subpageTitle="Blog" />
      <BlogContent />
    </TransitionWrapper>
  </>
)

Blog.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Blog
