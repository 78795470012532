import { useEffect, useState } from "react"

import endpoints from "api/endpoints"

const staleTime = 1000 * 60 * 5
const cache = {}

const memo = (variables, data) => {
  const queryStr = JSON.stringify(variables)
  const now = new Date().getTime()
  if (data) {
    cache[queryStr] = { data, cacheTime: now }
    return null
  }
  const memoized = cache[queryStr]
  if (!memoized || memoized?.cacheTime < now - staleTime) {
    return null
  }
  return cache[queryStr].data
}

const useGraphQLQuery = (query, variables, deps = []) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  const getData = async () => {
    setLoading(true)
    const memoized = memo(variables)
    if (memoized) {
      setData(memoized)
      return
    }
    const response = await fetch(endpoints.graphql, {
      method: "POST",
      body: JSON.stringify({ query, variables }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const dataJSON = await response.json()
    setData(dataJSON.data)
    memo(variables, dataJSON.data)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, deps)

  return { loading, data }
}

export default useGraphQLQuery
