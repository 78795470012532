import React, { useRef } from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import IconChevron from "assets/svg/icon_right_chevron.svg"
import Container from "components/common/Container"
import spacesToNbsp from "helpers/spacesToNbsp"

const StyledContainer = styled(Container)`
  margin-bottom: 5rem;
  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: 25px auto 25px;
    display: grid;
    align-items: center;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.mq.xs} {
    overflow-x: scroll;
    justify-content: flex-start;
    scroll-behavior: smooth;
  }
`

const StyledNavigationElement = styled.button`
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 900;
  padding: 2rem 4rem;
  background-color: ${({ theme, active }) => (active ? theme.color.purple : theme.color.cyan_400)};
  text-align: center;
  margin: 0 8px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.35s;

  p {
    position: relative;
    display: inline;

    &:after {
      background-color: ${({ color }) => color};
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(2.5rem, -50%);
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 50%;
    }
  }
`

const StyledIconChevron = styled(IconChevron)`
  display: none;
  transform: ${({ rotated }) => (rotated ? "rotate(180deg)" : "none")};
  justify-self: ${({ rotated }) => (rotated ? "flex-start" : "flex-end")};
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
  & path {
    fill: ${({ theme }) => theme.color.gray_400};
  }
`

const BlogNavigation = ({ categories, onClick, activeCategory }) => {
  const navigationClick = slug => () => onClick(slug)
  const scrolledRef = useRef()

  const scroll = direction => {
    const { width } = scrolledRef.current.getBoundingClientRect()
    const { scrollLeft } = scrolledRef.current
    switch (direction) {
      case "left": {
        scrolledRef.current.scrollTo(scrollLeft - width, 0)
        break
      }
      case "right": {
        scrolledRef.current.scrollTo(scrollLeft + width, 0)
        break
      }
      default: {
        throw Error("Missing direction")
      }
    }
  }
  const onLeftArrowClick = () => scroll("left")
  const onRightArrowClick = () => scroll("right")

  return (
    <StyledContainer>
      <StyledIconChevron onClick={onLeftArrowClick} rotated />
      <StyledWrapper ref={scrolledRef}>
        {categories.map(({ Name: name, slug, color }) => (
          <StyledNavigationElement active={activeCategory === slug} color={color} onClick={navigationClick(slug)}>
            <p>{spacesToNbsp(name)}</p>
          </StyledNavigationElement>
        ))}
      </StyledWrapper>
      <StyledIconChevron onClick={onRightArrowClick} />
    </StyledContainer>
  )
}

BlogNavigation.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  activeCategory: PropTypes.string.isRequired,
}

export default BlogNavigation
