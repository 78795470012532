import React, { useMemo } from "react"

import { graphql, useStaticQuery } from "gatsby"

import Section from "components/common/Section"
import useGraphQLQuery from "helpers/useGraphQLQuery"
import useQueryParams from "helpers/useQueryParams"

import BlogArticlesGrid from "./_components/BlogArticlesGrid"
import BlogNavigation from "./_components/BlogNavigation"
import BlogPagination from "./_components/BlogPagination"

const initialQuery = graphql`
  query InitialBlogQuery {
    allStrapiCategories {
      nodes {
        Name
        slug
        color
      }
    }
  }
`

const PAGE_SIZE = 7

const clientQuery = `query BlogPageQuery($start: Int, $category: String) {
        postsConnection (where: { category: { slug: $category } }) {
            aggregate {
                count
            }
        }
        posts(limit: ${PAGE_SIZE}, start: $start, sort: "created_at:DESC", where: { category: { slug: $category } }) {
            slug
            summary
            Title
            reading_time
            published_at
            author {
                Position
                First_name
                Last_name
                Avatar {
                    url
                }
            }
            main_image {
                url
            }
            category {
                Name
                color
            }
        }
    }
`

const QUERY_PARAMS = {
  PAGE: "page",
  CATEGORY: "cat",
}

const BlogContent = () => {
  const { allStrapiCategories } = useStaticQuery(initialQuery)
  const { queryParams, addParam, deleteParam } = useQueryParams()

  const params = useMemo(
    () => ({
      category: queryParams[QUERY_PARAMS.CATEGORY],
      start: queryParams[QUERY_PARAMS.PAGE] ? queryParams[QUERY_PARAMS.PAGE] * PAGE_SIZE : 0,
    }),
    [queryParams]
  )

  const { loading, data } = useGraphQLQuery(clientQuery, params, [params.category, params.start])

  const onCategoryClick = id => {
    if (queryParams[QUERY_PARAMS.CATEGORY] === id) deleteParam(QUERY_PARAMS.CATEGORY)
    else
      addParam([
        { name: QUERY_PARAMS.CATEGORY, value: id },
        { name: QUERY_PARAMS.PAGE, value: 0 },
      ])
  }
  const onPaginationClick = page => addParam({ name: QUERY_PARAMS.PAGE, value: page })
  const totalCount = data && data.postsConnection.aggregate.count

  return (
    <Section color="white">
      <BlogNavigation
        activeCategory={queryParams[QUERY_PARAMS.CATEGORY]}
        categories={allStrapiCategories.nodes}
        onClick={onCategoryClick}
      />
      <BlogArticlesGrid loading={loading} posts={data ? data.posts : []} />
      <BlogPagination
        currentPage={Number(queryParams[QUERY_PARAMS.PAGE]) || 0}
        onClick={onPaginationClick}
        pagesCount={totalCount ? Math.ceil(totalCount / PAGE_SIZE) : 0}
      />
    </Section>
  )
}

export default BlogContent
