import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "components/common/Container"

const StyledDotsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 30px;
`

const StyledDot = styled.button`
  border: none;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  background: ${({ theme }) => theme.color.cyan_400};
  transform: ${({ active }) => `scale(${active ? 1 : 0.9})`};
  border-radius: 100%;
  transition: 0.15s;
  height: 15px;
  width: 15px;
  cursor: pointer;
  flex-shrink: 0;
  margin: 7px;
  flex-basis: 15px;
`

const BlogPagination = ({ currentPage, onClick, pagesCount }) => {
  const onDotClick = index => () => onClick(index)
  return (
    <Container size="xxl">
      <StyledDotsWrapper pagesCount={pagesCount}>
        {pagesCount > 1 &&
          [...Array(pagesCount)].map((_, index) => (
            <StyledDot active={currentPage === index} onClick={onDotClick(index)} />
          ))}
      </StyledDotsWrapper>
    </Container>
  )
}

BlogPagination.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
}

export default BlogPagination
